import { Injectable, inject } from '@angular/core';
import { User } from '../models/user';
import { HttpService } from './http.service';

@Injectable()
export class PermissionService {
  private readonly http = inject(HttpService);

  public async getPermissions(): Promise<UserPermissions> {
    return await this.http.get('api/featureFlags');
  }
}

export interface UserPermissions {
  AllCompanies_ViewCompanyProfile?: boolean;
  AllCompanies_ConfidentialInfo_Edit?: boolean;
  AllCompanies_PrivateInfo_Edit?: boolean;
  AllCompanies_PublicInfo_Edit?: boolean;
  Billing_InviteAdmin?: boolean;
  User_Impersonate?: boolean;
  User_CompleteProfile?: boolean;
  CompanyInvitations_AcceptDecline?: boolean;
  CompanyInvitations_View?: boolean;
  CompanyConnections_View?: boolean;
  Company_CreateFolders?: boolean;
  Company_DeleteFolders?: boolean;
  Company_ResourcePage_View?: boolean;
  Company_RenameFolders?: boolean;
  Company_UploadFiles?: boolean;
  Company_MoveFiles?: boolean;
  Company_ViewFiles?: boolean;
  Company_DownloadFiles?: boolean;
  Company_DeleteFiles?: boolean;
  Company_ResourcePage_Manage?: boolean;
  User_CreateFolders?: boolean;
  User_DeleteFiles?: boolean;
  User_DeleteFolders?: boolean;
  User_RenameFolders?: boolean;
  User_UploadFiles?: boolean;
  User_MoveFiles?: boolean;
  User_ViewFiles?: boolean;
  User_DownloadFiles?: boolean;
  User_UpdateProfile?: boolean;
  User_UpdateCommunicationRoles?: boolean;
  User_ChangePassword?: boolean;
  User_SetTwoFactorAuthentification?: boolean;
  User_InviteNewUser?: boolean;
  Company_SetTwoFactorAuthentification?: boolean;
  Company_SetAuthorizedDomains?: boolean;
  Company_SubscriptionInfo_View?: boolean;
  Workflow_View?: boolean;
  Workflow_Create?: boolean;
  Workflow_Publish?: boolean;
  Workflow_Unpublish?: boolean;
  Workflow_Delete?: boolean;
  CompanyList_View?: boolean;
  CompanyList_Create?: boolean;
  CompanyList_Delete?: boolean;
  CompanyList_RemoveCompany?: boolean;
  CompanyList_AddCompany?: boolean;
  Campaign_Create?: boolean;
  Campaign_View?: boolean;
  Campaign_Publish?: boolean;
  Campaign_SendReminders?: boolean;
  Campaign_Delete?: boolean;
  Campaign_Close?: boolean;
  Conversation_Search?: boolean;
  Conversation_Create?: boolean;
  Conversation_Read?: boolean;
  ConversationV2_Read?: boolean;
  Task_Assign?: boolean;
  Task_ViewAssigned?: boolean;
  Task_Respond?: boolean;
  Task_Reassign?: boolean;
  Search_GlobalSearch?: boolean;
  Company_ViewCompanyProfile?: boolean;
  Search_Contacts?: boolean;
  Search_Products?: boolean;
  Search_Files?: boolean;
  Company_ViewOtherCompanies?: boolean;
  Company_CreateNewCompany?: boolean;
  Company_Connect?: boolean;
  Company_Certify?: boolean;
  CompanyUser_Create?: boolean;
  CompanyUser_View?: boolean;
  CompanyUser_Edit?: boolean;
  CompanyUser_Inactivate?: boolean;
  CompanyUser_Activate?: boolean;
  CustomField_Create?: boolean;
  CustomField_Delete?: boolean;
  CustomField_Update?: boolean;
  CustomFieldSection_Create?: boolean;
  CustomFieldSection_Delete?: boolean;
  CustomFieldSection_Update?: boolean;
  CustomField_View?: boolean;
  CustomFieldSection_View?: boolean;
  Dct_CompanyMatching?: boolean;
  Company_PrivateInfo_Edit?: boolean;
  FavoriteContact_Manage?: boolean;
  Product_Create: boolean;
  Product_Delete: boolean;
  Product_Edit: boolean;
  Product_EditVendor: boolean;
  Product_Inactivate: boolean;
  Product_Reactivate: boolean;
  Reports_User_CreateFolders: boolean;
  Reports_Company_CreateFolders: boolean;
  [x: string]: boolean;
}
