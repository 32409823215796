import { Injectable, inject } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { PremiumFeatureModalComponent } from '../modals/premium-feature-modal/premium-feature-modal.component';

const DEFAULT_OPTIONS: NgbModalOptions = {
  centered: true,
  windowClass: '',
  size: 'lg',
};

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private readonly modalService = inject(NgbModal);

  public open(config: { content: any; inputs?: Record<string, any>; options?: NgbModalOptions }): NgbModalRef {
    const { content, inputs, options } = config;
    const modalRef = this.modalService.open(content, {
      ...DEFAULT_OPTIONS,
      ...options,
    });

    if (inputs) {
      [].forEach.call(Object.keys(inputs), (key: string) => {
        modalRef.componentInstance[key] = inputs[key];
      });
    }

    return modalRef;
  }

  public closeModals(): void {
    this.modalService.dismissAll();
  }

  public openPremiumFeatureModal(): void {
    this.open({
      content: PremiumFeatureModalComponent,
    });
  }
}
