import { Directive, ElementRef, Input, type SimpleChanges, inject } from '@angular/core';
import { ConfigService } from '../services/config.service';

@Directive({
  selector: '[appAssetSrc]',
  standalone: true,
})
export class AssetSrcDirective {
  private readonly ref = inject(ElementRef);
  @Input('appAssetSrc') value: string;

  public ngOnChanges(changes: SimpleChanges) {
    if (changes.value) {
      const ref = this.ref.nativeElement as HTMLElement;
      ref.setAttribute('src', `${ConfigService.settings.assetsBaseUrl}${this.value}`);
    }
  }
}
