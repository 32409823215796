import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LS_AUTH_DATA_KEY } from '../constants/ls-auth-data-key';
import { type AuthData } from '../models/authData';

@Injectable()
export class LocalStorageService {
  public tokenRefreshed$ = new Subject<any>();

  public getAuthData(): AuthData {
    const tokenData = this.get(LS_AUTH_DATA_KEY);
    if (!tokenData) {
      return null;
    }

    return tokenData;
  }

  public set(key: string, data: any): void {
    try {
      localStorage.setItem(key, JSON.stringify(data));
      if (key === LS_AUTH_DATA_KEY) {
        this.tokenRefreshed$.next(data);
      }
    } catch (e) {
      console.error('Error saving to localStorage', e);
    }
  }

  public get(key: string): any {
    try {
      return JSON.parse(localStorage.getItem(key));
    } catch (e) {
      console.error('Error getting data from localStorage', e);
      return null;
    }
  }

  public delete(key: string): void {
    localStorage.removeItem(key);
  }
}
